$randomNumber: random(360)-360+deg;
*{
  box-sizing:border-box;
  margin:0;
  padding:0;
  transition: all 0.3s ease-in-out;
}
body{
  height:100vh;
  width:100%;
  overflow:hidden;
  font-family: Tahoma,Verdana,Segoe,sans-serif; 
  background:rgb(246, 230, 182);
  font-size:15px;
}

.App{
  position:absolute;
  width: 100%;
  height: 100%;
  .playerOne{
    width:100%;
    position:absolute;
    top:0;
    left:0;
    display:block;
    padding:30px;
    //border-bottom:1px solid #000;
    height:33.3%;
  }
  .playerTwo{
    width:100%;
    display:block;
    position:absolute;
    bottom:0;
     padding:30px;
    left:0;
     height:33.3%;
   // border-top:1px solid #000;
  }
  .middleSection{
    width:100%;
    position:absolute;
    top:33%;
    left:0;
    display:block;
    //border-bottom:1px solid #000;
    height:33.3%;
    //background:rgb(250, 223, 177);
    //border-radius:10px;
    .played{
      background:rgb(250, 223, 177);
      border-radius:20px;
      padding:20px;
      -webkit-box-shadow: -2px -2px 38px -19px rgba(0,0,0,0.58);
      -moz-box-shadow: -2px -2px 38px -19px rgba(0,0,0,0.58);
      box-shadow: -2px -2px 38px -19px rgba(0,0,0,0.58);
      border:2px solid rgba(0,0,0,0.3);
      .card{
        position: absolute;
        left: 100px;
        top:20px;
        &:nth-child(even) {
          -ms-transform: rotate(-45deg); /* IE 9 */
          -webkit-transform: rotate(-45deg); /* Safari 3-8 */
          transform: rotate(-45deg);
        }
        &:nth-child(odd) {
          -ms-transform: rotate($randomNumber); /* IE 9 */
          -webkit-transform: rotate($randomNumber); /* Safari 3-8 */
          transform: rotate($randomNumber);
        }
       
      }
    }
    .col-md-3{
      width:25%;
      display:block;
      position:relative;
      height:100%;
      float:left;
    }
     .col-md-6{
      width:50%;
      display:block;
      position:relative;
      height:100%;
      float:left;
    }
  }
}
.card{
  display: inline-block;
  height: 150px;
  width: 120px;
  margin: 15px;
  padding: 5px;
  transition: all 0.2s ease-in-out;
  background-color:rgba(255,255,255,1);
  border-radius: 10px;
  -webkit-box-shadow: -1px -1px 22px -19px rgba(0,0,0,0.58);
  -moz-box-shadow: -1px -1px 22px -19px rgba(0,0,0,0.58);
  box-shadow: -1px -1px 22px -19px rgba(0,0,0,0.58);
  border:2px solid rgba(0,0,0,0.3);
}

.card:hover{
  background-color: rgba(255, 255, 255,0.5);
  cursor: pointer;
  -webkit-box-shadow: -2px -2px 33px -19px rgba(0,0,0,0.58);
-moz-box-shadow: -2px -2px 33px -19px rgba(0,0,0,0.58);
box-shadow: -2px -2px 33px -19px rgba(0,0,0,0.58);
}
.nextPlayer{
  position: fixed;
  top:0;
  left:0;
  background-color: rgba(0,0,0, 0.3);
  color:#fff;
  display: block;
  padding: 5px;
}
.controller-sec{
  position: fixed;
  top:0;
  right:0;
  background-color: rgba(0,0,0, 0.3);
  color:#fff;
  display: block;
  padding: 5px;
  z-index: 90;
}
.controller-sec button{
  margin: 5px;
}
.pick-type{
    position: relative;
    bottom:0;
    left:0;
    background-color: rgba(0,0,0, 0.3);
    color:#fff;
    display: block;
    padding: 5px;
    height: 80%;
    margin:10%;
    button{
  margin: 5px;
}
}
.deck{
  position:relative;
  .stack{
    position:absolute;
    z-index:20;
    left:100px;
    background-image:url('./assets/pattern.png');
    background-size: cover;
    background-position: center;
    color:#fff;
    font-size: 16px;
    font-weight: 600;
  }
  .cutter{
    position:relative;
     z-index:10;
     left:50px;
      -ms-transform: rotate(-90deg); /* IE 9 */
  -webkit-transform: rotate(-90deg); /* Safari 3-8 */
  transform: rotate(-90deg);
  }
}